<template>
    <v-dialog v-model="show" @keydown.esc="onCancel()" @keydown.enter="validate()" persistent scrollable width="99vw">
        <v-card>
            <v-card-title class="headline primary white--text">
                {{ $t("Resultado de exame:") }}
                <br />
                {{ name }}
            </v-card-title>

            <v-card-text v-if="result" style="height: 80vh">
                <v-card class="elevation-12 pb-2 pt-1" grid-list-xl>
                    <v-layout wrap v-if="result.noteFixed">
                        <v-flex xs12>
                            <v-textarea outlined auto-grow style="font-family: 'courier'" :value="result.noteFixed" disabled />
                        </v-flex>
                    </v-layout>
                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
                        <v-layout wrap class="pa-0 ma-0 my-2" v-for="group in result.groups" :key="group.title">
                            <v-flex xs12 ma-2>
                                <h2 v-if="group.title">{{ group.title }}</h2>
                            </v-flex>
                            <v-flex xs12>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Título do Item</th>
                                                <th class="text-left">Valor de referencia</th>
                                                <th class="text-left">Unidade</th>
                                                <th class="text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="i in group.items" :key="i.title">
                                                <td>
                                                    {{ i.title ? i.title : "" }}
                                                </td>
                                                <td>
                                                    {{ i.reference }}
                                                </td>
                                                <td>
                                                    {{ i.unit }}
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-if="editMode"
                                                        :label="$t('Resultado')"
                                                        v-model="i.value"
                                                        :rules="[rules.required]"
                                                        @keydown.enter="focusNext($event.target)"
                                                    />
                                                    <b v-else>{{ i.value }}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap class="pa-2 mx-2 my-4 elevation-6" v-if="editMode || (!editMode && result.notes)">
                            <v-flex xs12> <v-textarea auto-grow :label="$t('Notas')" v-model="result.notes" :readonly="!editMode" /> </v-flex>

                            <v-flex xs6 v-if="editMode">
                                <v-btn color="success" @click="addNote1" style="width: 100%" v-if="showNote1">
                                    {{ $t("Exame repetido e confirmado") }}
                                </v-btn>
                            </v-flex>
                            <v-flex xs4 offset-xs1>
                                <v-alert icon="error" text dense type="error"> <b>Impresso no laudo.</b> </v-alert>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap class="pa-2 mx-2 my-4 elevation-6" v-if="editMode || (!editMode && result.internalNotes)">
                            <v-flex xs12 mt-2> <v-textarea auto-grow :label="$t('Notas Internas')" v-model="result.internalNotes" :readonly="!editMode" /> </v-flex>
                        </v-layout>
                    </v-form>
                </v-card>
            </v-card-text>

            <v-alert v-if="!editMode" icon="info" prominent text dense type="error"> <b>IMPORTANTE: </b> Fazendo a liberação desse exame, o mesmo estará disponível. </v-alert>
            <v-divider></v-divider>
            <v-spacer></v-spacer>

            <v-card-actions>
                <v-layout wrap>
                    <v-flex xs6>
                        <v-btn color="success" @click="validate" style="width: 100%">
                            <v-icon dark left>check_circle</v-icon>
                            {{ editMode ? $t("Salvar") : $t("Liberar") }}
                        </v-btn>
                    </v-flex>
                    <v-flex xs6>
                        <v-btn color="error" @click="onCancel" style="width: 100%">
                            <v-icon dark left>cancel</v-icon>
                            {{ $t("Cancelar") }}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import rules from "@/helpers/rules";

const itemClean = { groups: [{ title: "", items: [{ title: "", reference: "", unit: "" }] }], notes: "", internalNotes: null };

export default {
    props: {
        idService: {},
        idExam: {},
        name: {},
        itemToEdit: {},
        show: {
            type: Boolean,
            required: true,
        },
        editMode: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            result: JSON.parse(JSON.stringify(itemClean)),
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                this.$nextTick(() => {
                    if (newVal == null) {
                        this.result = JSON.parse(JSON.stringify(itemClean));
                    } else {
                        this.result = { ...newVal };
                        console.log(newVal);
                    }
                });
            },
        },
    },

    computed: {
        showNote1() {
            return this.result && this.result.notes && this.result.notes.includes("Exame repetido e confirmado.") ? false : true;
        },
    },

    methods: {
        focusNext(elem) {
            const currentIndex = Array.from(elem.form.elements).indexOf(elem);
            elem.form.elements.item(currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0).focus();
        },
        addNote1() {
            this.result.notes = this.result.notes ? `${this.result.notes}Exame repetido e confirmado.` : "Exame repetido e confirmado.";
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$http
                    .put(`results/${this.editMode ? "typed" : "release"}`, this.idService, { idExam: this.idExam, result: this.result })
                    .then((result) => {
                        this.$emit("onSend");
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Resultado inserido com sucesso."));
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao inserir resultado."));
                    });
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>