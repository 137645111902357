<template>
    <v-container grid-list-md text-xs-center fluid mb-16>
        <h1>Atendimento</h1>
        <v-divider class="mb-4"></v-divider>
        <v-layout wrap v-if="service">
            <v-flex xs12>
                <v-text-field :label="$t('Identificação')" :value="service.id" readonly />
                <v-text-field :label="$t('Unidade')" :value="service.store" readonly />
                <v-text-field :label="$t('Cliente')" :value="service.client" readonly />
                <v-text-field :label="$t('Convênio')" :value="service.partner" readonly v-if="service.partner" />
                <v-text-field :label="$t('Data de entrega')" :value="deliveryDate" readonly />

                <v-switch :input-value="service.solicittedByClient" label="Solicitado pelo paciente" class="mx-0 px-0" color="success" inset readonly />
                <v-text-field v-if="service.solicittedByClient == false" :label="$t('Médico')" :value="service.doctor" disabled />
            </v-flex>
            <v-flex xs12 v-if="service.exams && service.exams.length > 0">
                <v-data-table :headers="headers" :items="service.exams" :items-per-page="-1" class="elevation-1" hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        {{ item.result && item.result.name ? item.result.name : item.ref.name }}
                    </template>
                    <template v-slot:item.releasedDate="{ item }">
                        {{ $moment(item.releasedDate).format("DD/MM/YY HH:mm") }}
                    </template>
                    <template v-slot:item.extra="{ item }">
                        <v-btn v-if="!item.status || item.status == 0" color="success" class="my-1" x-small style="width: 100%" @click="keyResult(item)"> Digitar resultado </v-btn>
                        <v-btn v-if="item.status == 2" class="my-1" color="info" x-small style="width: 50%" @click="printExam(item)">
                            <v-icon dark small left>print</v-icon> Imprimir
                        </v-btn>

                        <v-chip v-if="item.status == 1 && !isResponsible" class="ma-0" outlined color="error"> Aguardando aprovação do responsável </v-chip>

                        <v-btn v-if="item.status == 1 && isResponsible" class="my-1" color="warning" x-small style="width: 50%" @click="releaseResult(item)">
                            Liberar resultado
                        </v-btn>
                        <v-btn v-if="item.status >= 1 && isResponsible" class="my-1" color="error" x-small style="width: 50%" @click="deleteResult(item)">
                            <v-icon dark small left>delete</v-icon> Deletar
                        </v-btn>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex xs12>
                <Result
                    :editMode="result.edit"
                    :show="result.show"
                    :idService="result.idService"
                    :idExam="result.idExam"
                    :name="result.name"
                    :itemToEdit="result.result"
                    @close="result.show = false"
                    @onSend="closeResultScreen()"
                />
            </v-flex>

            <v-flex xs12 v-if="service.status >= 1">
                <v-btn color="info" dark style="width: 100%" @click="print()"> <v-icon dark left>print</v-icon> Imprimir laudo completo </v-btn>
            </v-flex>
            <v-flex xs12 v-if="service.status >= 1">
                <v-alert icon="info" prominent text dense :type="service.printOnePerPage ? 'error' : 'info'">
                    <v-switch v-model="service.printOnePerPage" class="mx-0 px-0 text--black" color="error" inset @change="togglePrintOnePerPage()">
                        <template v-slot:label>
                            <span class="grey--text">Uma exame por folha</span>
                        </template>
                    </v-switch>
                    <div v-if="!service.printOnePerPage">
                        <b>IMPORTANTE: </b> Por padrão os exames serão compactados para otimizar o número de folhas.
                        <br />
                        <b>Habilite esta opção em caso de problema de impressão.</b>
                    </div>
                </v-alert>
            </v-flex>
            <v-flex xs12 sm6>
                <v-btn color="grey" dark style="width: 100%" @click="returnScreen()"> <v-icon dark left>keyboard_return</v-icon> Voltar para lista de exames </v-btn>
            </v-flex>
            <v-flex xs12 sm6 v-if="isAdmin">
                <v-btn color="error" dark style="width: 100%" @click="editAtendiment" class="mt-12 mt-sm-0"> <v-icon dark left>edit</v-icon> Editar o atendimento </v-btn>
            </v-flex>
        </v-layout>

        <ConfirmModal ref="confirm" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import Result from "./Result";
import { mapGetters } from "vuex";

export default {
    components: { ConfirmModal, Result },

    data() {
        return {
            id: null,
            service: null,

            isResponsible: false,
            isAdmin: false,

            headers: [
                { text: "Exame", value: "name", sortable: true },
                { text: "Liberado em", value: "releasedDate", sortable: true },
                { text: "Liberado por", value: "releasedBy", sortable: true },
                { text: "Digitado por", value: "typedBy", sortable: true },
                { text: "", value: "extra", align: "center", sortable: false },
            ],

            result: {
                edit: false,
                show: false,
                examPlans: null,
                idService: null,
                idExam: null,
                name: null,
            },
        };
    },

    computed: {
        ...mapGetters(["verifyRole"]),
        deliveryDate: {
            get: function () {
                return this.service.deliveryDate ? this.$moment(this.service.deliveryDate).format("DD/MM/YYYY") : "";
            },
        },
    },

    methods: {
        keyResult(exam) {
            if (exam && exam.ref && exam.ref.plans) {
                this.result.edit = true;
                this.result.idService = this.service.id;
                this.result.idExam = exam._id;
                this.result.name = exam.result && exam.result.name ? exam.result.name : exam.ref.name;
                this.result.result = { groups: exam.ref.plans, notes: exam.notes, internalNotes: exam.internalNotes, noteFixed: exam.ref.notes };
                this.result.show = true;
            }
        },
        releaseResult(exam) {
            if (exam && exam.status == 1) {
                this.result.edit = false;
                this.result.idService = this.service.id;
                this.result.idExam = exam._id;
                this.result.name = exam.result && exam.result.name ? exam.result.name : exam.ref.name;
                this.result.result = { groups: exam.result, notes: exam.notes, internalNotes: exam.internalNotes, noteFixed: exam.ref.notes };
                this.result.show = true;
            }
        },
        togglePrintOnePerPage() {
            this.$http
                .patch("results", { id: this.service.id, data: { printOnePerPage: this.service.printOnePerPage } })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Resultado inserido com sucesso."));
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao inserir resultado."));
                })
                .finish(() => this.update());
        },

        deleteResult(exam) {
            this.$refs.confirm.open("Deseja continuar?", "Os resultados serão apagados e será necessario digita-los novamente.").then((confirm) => {
                if (confirm) {
                    this.$http
                        .put("results/delete", this.service.id, { idExam: exam._id })
                        .then((result) => {
                            this.update();
                            this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Resultado inserido com sucesso."));
                        })
                        .catch((error) => {
                            this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao inserir resultado."));
                        });
                }
            });
        },

        editAtendiment() {
            this.$refs.confirm.open("Deseja continuar?", "Ao editar um atendimento, todos os resultados já digitalizados serão perdidos.").then((confirm) => {
                if (confirm) {
                    this.$router.replace({ path: "/service", query: { id: this.service.id } });
                }
            });
        },

        closeResultScreen() {
            this.update();
            this.result.show = false;
        },

        update() {
            this.$http
                .get("results/" + this.id)
                .then((result) => {
                    this.service = result;
                })
                .catch(() => {
                    this.service = null;
                });
        },

        returnScreen() {
            this.$router.push({ path: `/results` });
        },

        printExam(item) {
            this.$http
                .getFile(
                    `results/printExam/${this.service.id}/${item._id}`,
                    `resultadoParcial(${this.service.id})(${this.service.client})(${this.$moment().format("YYYYMMDD HHmmss")})`
                )
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao gerar PDF."));
                });
        },
        print() {
            this.$http
                .getFile(`results/print/${this.service.id}`, `resultado(${this.service.id})(${this.service.client})(${this.$moment().format("YYYYMMDD HHmmss")})`)
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao gerar PDF."));
                });
        },
    },

    mounted() {
        this.isResponsible = this.verifyRole("RESPONSIBLE");
        this.isAdmin = this.verifyRole("ADMIN");

        if (this.$route.params.id) {
            this.id = this.$route.params.id;
        }
        this.update();
    },
};
</script>