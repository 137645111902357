<template>
    <v-dialog v-model="show" @keydown.esc="onCancel()" @keydown.enter="onConfirm()" persistent max-width="800">
        <v-card>
            <v-card-title class="headline primary white--text">{{ title }}</v-card-title>

            <v-card-text class="my-12 text-h6">
                {{ msg }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="onConfirm">
                    <v-icon dark left>check_circle</v-icon>
                    {{ confirmText }}
                </v-btn>
                <v-btn color="error" @click="onCancel">
                    <v-icon dark left>cancel</v-icon>
                    {{ cancelText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmModal",
    props: {
        confirmText: {
            type: String,
            default: "Sim",
        },
        cancelText: {
            type: String,
            default: "Não",
        },
    },
    data() {
        return {
            title: "",
            msg: "",
            show: false,
            resolve: null,
            reject: null,
        };
    },
    methods: {
        open(title, msg) {
            this.title = title;
            this.msg = msg;
            this.show = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        onConfirm() {
            this.resolve(true);
            this.show = false;
        },
        onCancel() {
            this.resolve(false);
            this.show = false;
        },
    },
};
</script>